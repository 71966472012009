import React, { useState } from 'react';

const Login = ({ setCanEdit }) => {
  const [token, setToken] = useState('');

  const onClickLogin = async () => {
    try {
      const data = await fetch('https://api.thefyrewire.com/dev/login', { method: 'POST', body: JSON.stringify({ token })}).then(d => d.json());
      if (data.status !== 200) return;
      setCanEdit(true);
    } catch (e) {
      setCanEdit(false);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return false;
    onClickLogin();
  }

  return (
    <div>
      <div className="dimmer">
        <input type="password" onInput={e => setToken(e.target.value)} onKeyDown={handleKeyDown} />
        <button onClick={onClickLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
