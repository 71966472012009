import React, { useState } from 'react';
import './App.css';
import EditorPanel from './components/EditorPanel';
// import Viewer from './components/Viewer';
import Login from './components/Login';

// import socket from 'socket.io-client';
import { Switch, Route } from 'react-router-dom';

const App = () => {
  // const io = socket('http://localhost:8000/');
  // io.connect();
  // io.on('connected', () => {
  //   console.log('connected');
  // });
  // io.on('document', (data) => {
  //   console.log(data);
  // });

  // const handleKeyDown = (e) => {
  //   if (e.ctrlKey && (e.which == 83)) {
  //     e.preventDefault();
  //     handleSave();
  //     return false;
  //   }
  // }

  // const handleSave = () => {
  //   console.log('saving');
  //   io.emit('save');
  // }

  const [canEdit, setCanEdit] = useState(false);


  return (
    // <div className="App" onKeyDown={handleKeyDown}>
    <div className="App">
      <Switch>
        <Route path="/" exact>
          { canEdit ? <EditorPanel canEdit={canEdit} /> : <Login setCanEdit={setCanEdit} /> }
          {/* <EditorPanel canEdit={canEdit} /> */}
        </Route>
        {/* <Route path="/view">
          <Viewer />
        </Route> */}
      </Switch>
    </div>
  );
}

export default App;
