import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";

import socket from 'socket.io-client';
import fs from 'fs';
 
import Editor, { monaco } from '@monaco-editor/react';
import EditorSelection from '../shared/EditorSelection';

const EditorPanel = ({ canEdit = false }) => {
  // const [content, setContent] = useState({
  //   html: '',
  //   css: '',
  //   js: ''
  // });
  // const io = socket('http://localhost:8000/');
  // const io = socket('https://thefyrewire-dev-api.herokuapp.com/', { transport: ['websocket'] });
  const io = socket('https://events.thefyrewire.com', { path: '/devdojo', transport: ['websocket'] });
  io.connect();
  io.on('connected', () => {
    console.log('connected');
  });
  io.on('document', (data) => {
    console.log(data);
    // editors.html.getModel().setValue(data.html);
    // editors.css.getModel().setValue(data.css);
    // editors.js.getModel().setValue(data.js);
    content.html = data.html;
    content.css = data.css;
    content.js = data.js;
  });

  io.on('refresh', () => {
    document.querySelector('.view > iframe').src += '';
  });
  
  const content = {
    html: '',
    css: '',
    js: ''
  }

  let inSync = false;

  // const [socketID, setSocketID] = useState('');

  let decorations = [];
  // let currentSelection = '';

  // const editorRef = useRef();
  const editors = {};

  const handleEditorDidMount = (editor, lang) => {
    monaco.init().then(monaco => {
    // editorRef.current = editor;
    editors[lang] = editor;
    editor.getModel().updateOptions({ tabSize: 2 });

    editors[lang].getModel().setValue(content[lang]);

    editor.onDidChangeModelContent((ev) => {
      if (editor.getValue() === content[lang]) {
        return;
      }
      content[lang] = editor.getValue();
      io.emit('update', { lang, content: editor.getValue() });
      inSync = false;
    });

    io.on('update', (data) => {
      if (data.lang !== lang) return;

      content[lang] = data.content;
      editor.getModel().setValue(data.content);
      decorations = editor.deltaDecorations(decorations, [{ range: new monaco.Range(1,1,1,1), options : { } }]);
      document.querySelectorAll('.myCursor').forEach(el => el.remove());
      // editor.focus();
    });

    io.on('updateSelection', (data) => {
      if (data.lang !== lang || !canEdit) return;

      // console.log(new monaco.Selection(...data.selections));
      // console.log(data.selections);
      // editor.setSelection(new monaco.Selection(1, 1, 1, 9, 1))

      const editorSelections = data.selections.map((s) =>
        EditorSelection.fromObject(s),
      );

      const newDecorations = [];

      let primarySelection;
      for (const selection of editorSelections) {
        const ranges = selection.toMonacoRanges();

        // First selection is the primary selection.
        if (!primarySelection) {
          primarySelection = selection;
        }

        newDecorations.push({
          range: ranges.cursor,
          // options: { className: `myCursor ${color}` },
          options: { type: 'cursor', className: `myCursor` },
        });

        if (selection.hasSelection()) {
          newDecorations.push({
            range: ranges.selection,
            // options: { className: `mySelection ${color}` },
            options: { type: 'selection', className: `mySelection` },
          });
        }
      }

      const selections = newDecorations.map(selection => {
        return new monaco.Selection(...Object.values(selection.range));
      });

      if (selections.length > 0) {
        // editor.setSelections(selections);
        // editor.focus();
      }

      const position = newDecorations.filter(dec => dec.options.type === 'cursor').map(selection => {
        return new monaco.Position(...Object.values(selection.range));
      });

      if (position.length > 0) {
        // console.log(position);
        // editor.setPosition(position[0]);
        // editor.focus();

        // const { cursorRow, cursorCol } = primarySelection;
        // console.log(primarySelection);
        editor.revealPositionInCenterIfOutsideViewport(
          position[0]
        );
        // editor.focus();
      }

      // console.log(newDecorations);

      if (!editor.hasTextFocus()) {
        decorations = editor.deltaDecorations(
          decorations,
          newDecorations,
        );
      } else if (decorations.length > 0) {
        decorations = editor.deltaDecorations(decorations, [{ range: new monaco.Range(1,1,1,1), options : { } }]);
        document.querySelectorAll('.myCursor').forEach(el => el.remove());
      }


      // console.log(decorations);

      // // console.log(new monaco.Selection(newDecorations[0]))
      // // editor.setSelection(newDecorations[0]);

      // if (/*scrollToCursor*/true) {
      //   const { cursorRow, cursorCol } = primarySelection;
      //   editor.revealPositionInCenterIfOutsideViewport(
      //     new monaco.Position(cursorRow, cursorCol),
      //   );
      // }

      inSync = true;

    });

    editor.onDidChangeCursorPosition((data) => {
      if (data.source === 'api' || !canEdit) return;
    });

    editor.onDidChangeCursorSelection(({ selection, secondarySelections, source }) => {
      if (source === 'api' || !canEdit) return;

      if (inSync) {
        // decorations = editor.deltaDecorations(decorations, []);
        console.log('sunk');
        return;
      };

      const editorSelections = [
        EditorSelection.fromMonacoChange(selection).toObject(),
      ];
      for (const secondarySelection of secondarySelections) {
        editorSelections.push(
          EditorSelection.fromMonacoChange(
            secondarySelection,
          ).toObject(),
        );
      }
      io.emit('updateSelection', { lang, selections: editorSelections });
      inSync = false;
    });

    editor.onDidBlurEditorText(() => {
      console.log('did blur');
      // editor.setSelection(new monaco.Range(0, 0, 0, 0));
      decorations = editor.deltaDecorations(decorations, [{ range: new monaco.Range(1,1,1,1), options : { } }]);
      document.querySelectorAll('.myCursor, .mySelection').forEach(el => el.remove());
      inSync = false;
    });

    editor.onDidFocusEditorText(() => {
      console.log('did focus');
      // editor.setSelection(new monaco.Range(0, 0, 0, 0));
      decorations = editor.deltaDecorations(decorations, [{ range: new monaco.Range(1,1,1,1), options : { } }]);
      document.querySelectorAll('.myCursor, .mySelection').forEach(el => el.remove());
      inSync = false;
    });

    editor.addAction({
      id: 'save-action',
      label: 'Save',
      // eslint-disable-next-line no-bitwise
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => {
        // this.formatDocument();
        // this.$emit('save');
        console.log(`i do save ${lang} nao!`);
        io.emit('save', {
          html: editors.html.getValue(),
          css: editors.css.getValue(),
          js: editors.js.getValue()
        });
      },
    });

    // editor.addAction({
    //   id: 'save-action',
    //   label: 'Save',
    //   // eslint-disable-next-line no-bitwise
    //   keybindings: [5 | 49],
    //   run: () => {
    //       // this.formatDocument();
    //       // this.$emit('save');
    //       console.log('Saving!');
    //   },
    // });

    const monokaiTheme = {
      "base": "vs-dark",
      "inherit": true,
      "rules": [
        {
          "foreground": "75715e",
          "token": "comment"
        },
        {
          "foreground": "e6db74",
          "token": "string"
        },
        {
          "foreground": "ae81ff",
          "token": "constant.numeric"
        },
        {
          "foreground": "ae81ff",
          "token": "constant.language"
        },
        {
          "foreground": "ae81ff",
          "token": "constant.character"
        },
        {
          "foreground": "ae81ff",
          "token": "constant.other"
        },
        {
          "foreground": "f92672",
          "token": "keyword"
        },
        {
          "foreground": "f92672",
          "token": "storage"
        },
        {
          "foreground": "66d9ef",
          "fontStyle": "italic",
          "token": "storage.type"
        },
        {
          "foreground": "a6e22e",
          "fontStyle": "underline",
          "token": "entity.name.class"
        },
        {
          "foreground": "a6e22e",
          "fontStyle": "italic underline",
          "token": "entity.other.inherited-class"
        },
        {
          "foreground": "a6e22e",
          "token": "entity.name.function"
        },
        {
          "foreground": "fd971f",
          "fontStyle": "italic",
          "token": "variable.parameter"
        },
        {
          "foreground": "f92672",
          "token": "entity.name.tag"
        },
        {
          "foreground": "a6e22e",
          "token": "entity.other.attribute-name"
        },
        {
          "foreground": "66d9ef",
          "token": "support.function"
        },
        {
          "foreground": "66d9ef",
          "token": "support.constant"
        },
        {
          "foreground": "66d9ef",
          "fontStyle": "italic",
          "token": "support.type"
        },
        {
          "foreground": "66d9ef",
          "fontStyle": "italic",
          "token": "support.class"
        },
        {
          "foreground": "f8f8f0",
          "background": "f92672",
          "token": "invalid"
        },
        {
          "foreground": "f8f8f0",
          "background": "ae81ff",
          "token": "invalid.deprecated"
        },
        {
          "foreground": "cfcfc2",
          "token": "meta.structure.dictionary.json string.quoted.double.json"
        },
        {
          "foreground": "75715e",
          "token": "meta.diff"
        },
        {
          "foreground": "75715e",
          "token": "meta.diff.header"
        },
        {
          "foreground": "f92672",
          "token": "markup.deleted"
        },
        {
          "foreground": "a6e22e",
          "token": "markup.inserted"
        },
        {
          "foreground": "e6db74",
          "token": "markup.changed"
        },
        {
          "foreground": "ae81ffa0",
          "token": "constant.numeric.line-number.find-in-files - match"
        },
        {
          "foreground": "e6db74",
          "token": "entity.name.filename.find-in-files"
        }
      ],
      "colors": {
        "editor.foreground": "#F8F8F2",
        "editor.background": "#272822",
        "editor.selectionBackground": "#49483E",
        "editor.lineHighlightBackground": "#3E3D32",
        "editorCursor.foreground": "#F8F8F0",
        "editorWhitespace.foreground": "#3B3A32",
        "editorIndentGuide.activeBackground": "#9D550FB0",
        "editor.selectionHighlightBorder": "#222218"
      }
    }

    const devwarsTheme = {
      "base": "vs-dark",
      "inherit": true,
      "rules": [
        {
          "foreground": "546178",
          "fontStyle": "italic",
          "token": "comment"
        },
        {
          "foreground": "00c9ff",
          "token": "string"
        },
        {
          "foreground": "00c9ff",
          "token": "constant.numeric"
        },
        {
          "foreground": "ff007d",
          "token": "constant.language"
        },
        {
          "foreground": "00c9ff",
          "token": "constant.character"
        },
        {
          "foreground": "ff007d",
          "token": "constant.other"
        },
        {
          "foreground": "ff007d",
          "token": "keyword"
        },
        {
          "foreground": "ff007d",
          "token": "storage"
        },
        {
          "foreground": "ff007d",
          "token": "storage.type"
        },
        {
          "foreground": "ffffff",
          "token": "entity.name.class"
        },
        {
          "foreground": "00c9ff",
          "token": "entity.other.inherited-class"
        },
        {
          "foreground": "ffffff",
          "token": "entity.name.function"
        },
        {
          "foreground": "00c9ff",
          "token": "variable.parameter"
        },
        {
          "foreground": "ff007d",
          "token": "entity.name.tag"
        },
        {
          "foreground": "ffffff",
          "token": "entity.other.attribute-name"
        },
        {
          "foreground": "ffffff",
          "token": "support.function"
        },
        {
          "foreground": "00c9ff",
          "token": "support.constant"
        },
        {
          "foreground": "ffffff",
          "token": "support.type"
        },
        {
          "foreground": "ffffff",
          "token": "support.class"
        },
        {
          "foreground": "fd002a",
          "token": "invalid"
        },
        {
          "foreground": "fd002a",
          "token": "invalid.deprecated"
        },
        {
          "foreground": "C792EA",
          "token": "meta.structure.dictionary.json string.quoted.double.json"
        },
        {
          "foreground": "C792EA",
          "token": "meta.diff"
        },
        {
          "foreground": "C792EA",
          "token": "meta.diff.header"
        },
        {
          "foreground": "FF5370",
          "token": "markup.deleted"
        },
        {
          "foreground": "C3E88D",
          "token": "markup.inserted"
        },
        {
          "foreground": "C792EA",
          "token": "markup.changed"
        },
        {
          "foreground": "00c9ff",
          "token": "constant.numeric.line-number.find-in-files - match"
        },
        {
          "foreground": "ff007d",
          "token": "entity.name.filename.find-in-files"
        },
        { token: 'tag', foreground: 'ff007d' },
        { token: 'meta.tag', foreground: 'ff007d' },
        { token: 'metatag', foreground: 'ff007d' },
        { token: 'metatag.content.html', foreground: 'ffffff' },
        { token: 'metatag.html', foreground: 'ff007d' },
        { token: 'attribute.name', foreground: 'ffffff' },
        { token: 'attribute.value', foreground: '00c9ff' },
        { token: 'attribute.value.number.css', foreground: '00c9ff' },
        { token: 'attribute.value.unit.css', foreground: '00c9ff' },
        { token: 'attribute.value.hex.css', foreground: '00c9ff' }
      ],
      "colors": {
        "editor.foreground": "#ffffff",
        "editor.background": "#0b0c11",
        "editor.selectionBackground": "#2a313c",
        "editor.lineHighlightBackground": "#2a313c",
        "editorCursor.foreground": "#F8F8F0",
        "editorWhitespace.foreground": "#3B3A32",
        "editorIndentGuide.activeBackground": "#9D550FB0",
        "editor.selectionHighlightBorder": "#222218"
      }
    }
    
    // monaco.init().then(monaco => {
      // monaco.editor.defineTheme('monokaiTheme', monokaiTheme);
      // monaco.editor.setTheme('monokaiTheme');
      monaco.editor.defineTheme('devwarsTheme', devwarsTheme);
      monaco.editor.setTheme('devwarsTheme');
    });
  }

  // const listenEditorChanges = () => {
  //   editorRef.current.onDidChangeModelContent(ev => {
  //     console.log(ev);
  //     console.log(editorRef.current.getValue());
  //   })
  // }

  // io.on('update', ({ lang, content }) => {
  //   // console.log(data);
  //   setContent({ ...content, [lang]: content });
  // });

  // const focusEditor = (lang) => {
  //   editors[lang].deltaDecorations(decorations, []);
  //   inSync = false;
  // }

  // const blurEditor = (lang) => {
  //   // editors[lang].deltaDecorations(decorations, []);
  //   // inSync = false;
  //   editors[lang].setSelection(new monaco.Selection(0, 0));
  // }

  return (
    <>
      {
        // ['html', 'css', 'js'].map(lang => (
        //   <Editor
        //     key={lang}
        //     height="30vh"
        //     theme="light"
        //     language={lang === 'js' ? 'javascript' : lang}
        //     editorDidMount={handleEditorDidMount}
        //     options={{
        //       contextmenu: false,
        //       minimap: {
        //         enabled: false
        //       }
        //     }}
        //   />
        // ))
      }
      {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
        {/* <div style={{ backgroundColor: 'blue', width: '50%', height: '50vh', flex: 1 }}>

        </div>
        <div style={{ backgroundColor: 'orange', width: '50%', height: '50vh', flex: 1 }}>

        </div>
        <div style={{ backgroundColor: 'green', width: '100%', height: '50vh', flex: 1 }}>

        </div> */}
        <div className="grid-container">
          {/* <div class="HTML"></div>
          <div class="JS"></div>
          <div class="CSS"></div> */}
          {
            [ 'html', 'css', 'js'].map(lang => (
              <div key={lang} className={lang, 'editor'}
                // onFocus={() => focusEditor(lang)}
                // onBlur={() => blurEditor(lang)}
              >
                <Editor
                  height="100%"
                  theme="light"
                  language={lang === 'js' ? 'javascript' : lang}
                  // value={content[lang]}
                  editorDidMount={(_, ev) => handleEditorDidMount(ev, lang)}
                  loading=''
                  options={{
                    contextmenu: false,
                    minimap: {
                      enabled: false
                    },
                    lineNumbers: 'off',
                    glyphMargin: false,
                    folding: false,
                    lineDecorationsWidth: 10,
                    lineNumbersMinChars: 0,
                    renderLineHighlight: 'none',
                    hideCursorInOverviewRuler: true,
                    overviewRulerBorder: false,
                    // selectionHighlight: false,

                    readOnly: false
                  }}
                />
              </div> 
            ))
          }
          <div className="view">
            {/* <iframe src="http://localhost:3000/view" frameBorder="0" /> */}
            <iframe title="viewer" src={`https://dev.thefyrewire.com/view?v=${(new Date()).toISOString()}`} frameBorder="0" />
          </div>
        </div>
      {/* </div> */}
      {/* <div style={{ backgroundColor: '#272822', width: '100%', height: '50vh', flex: 1, borderTop: '1px solid #3f403c' }}>

      </div> */}

    </>
  )
}

export default EditorPanel;